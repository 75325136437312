<template>
    <div class="vacant-list">
        <img src="/__MIXTELL_VACANTS.svg" style="margin-bottom: 40px" />
        <div class="" v-for="v in vacants">
            <div class="vacant">
                <div class="photo">
                    <img :src="v.photo" />
                </div>
                <div class="about">
                    <div class="about-item border" style="font-weight: bold; max-width: 280px;">{{ v.name }}</div>
                    <div class="about-item border">
                        <div>Зарплата от <br/><span style="font-size: x-large; font-weight: bold; color: #00a0bc;">{{ v.count }} р</span></div>
                    </div>
                    <div class="about-item">
                        <div class="more" @click="v.show=true">Подробнее</div>
                    </div>
                </div>
            </div>
            <div class="vacant" v-show="v.show">
                <div class="photo">
                </div>
                <div class="vacant-about" v-html="v.about"></div>
            </div>            
        </div>
    </div>
</template>

<script>

import Video from './Video.vue'

export default {
    components: {
        Video
    }, 
    mounted() {
    },
    props: {
        vacants: {
            type: Array,
        },
    },
    data() {
        return {
            about: false,
        }
    },
    methods: {
    },
}

</script>

<style> 
.vacant p {
    line-height: 1.5;
    font-size: larger;
    margin-bottom: 15px;
}

.vacant ul {
    line-height: 1.5;
    font-size: larger;
    list-style-type: none;
}

.vacant ul > li {
    margin-bottom: 15px;
}

.vacant ul > li::before {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    background: url(../public/tild3061-6530-4261-b262-346535646535Ellipse_42.png);
    background-size: contain;
    margin-right: 20px;
}
</style>

<style scoped>

.vacant-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.vacant {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 70px;
}

.vacant .photo {
    min-width: 265px;
}

.vacant .about {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    border: 3px solid #78cddc;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: larger;
    border-radius: 40px;
}

.vacant .about-item {
    min-height: 50px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.vacant .about-item.border {    
    border-right: 3px solid #78cddc;;
}

.more {
    border-radius: 17px;
    background-color: #009da3;
    color: white;
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    cursor: pointer;
}

.more:hover {
    opacity: 80%;    
}

</style>