<template>
    <Splide :options="{ rewind: true, pagination: false, perPage: 1, perMove: 1, gap: '15px' }" :autoWidth="true" class="slide">
        <SplideSlide v-for="s in stories" :key="s.person">
            <div class="story-item">
                <img src="/__MIXTELL_STORY_TITLE.svg" />
                <div class="content">
                    <div class="person">
                        <div class="person-name">{{ s.who }}</div>
                        <div class="person-photo">
                            <div class="person-photo-wrapper">
                                <img :src="s.photo" />
                            </div>                            
                        </div>
                    </div>
                    <div class="story">
                        <div class="transit">
                            <div class="cariers">
                                <div class="carier">
                                    <div class="carier-title">{{ s.current.title }}</div>
                                    <div class="carier-date">{{ s.current.date }}</div>
                                </div>
                                <div class="carier" style="margin-top: 70px">
                                    <div class="carier-title">{{ s.prev.title }}</div>
                                    <div class="carier-date">{{ s.prev.date }}</div>
                                </div>
                            </div>
                            <img src="/arrow.svg" style="height: 200px; margin-top: 20px; margin-left: 20px;" />
                        </div>
                        <div class="text">
                            {{ s.text }}
                        </div>
                    </div>                    
                </div>
            </div>            
        </SplideSlide>
    </Splide>
</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

export default {
    components: {
        Splide, SplideSlide
    }, 
    mounted() {
    },
    props: {
        stories: {
            type: Array,
        },
    },
    data() {
        return {
        }
    },
    methods: {
    },
}

</script>

<style scoped>

.slide {
    width: 1200px;
}

.person-name {
    font-size: xx-large;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 70px;
    padding-bottom: 10px;
    border-bottom: 2px solid #c0c5e0;;
    min-width: 450px;
}

.story-item {
    display: flex;
    flex-direction: column;
    padding: 40px 70px 40px 70px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 25px;
    box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.1);
}

.story-item .content {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin-top: 40px;
}

.story-item .person {
    display: flex;
    flex-direction: column;
}

.story-item  .story {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.story .transit {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
}

.story .text {
    padding: 20px;
    border: 1px solid rgba(192,197,224, 0.5);
    border-radius: 20px;
    box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.1);
    margin-left: -100px;
}

.person-photo-wrapper {
    width: 350px;
    clip-path: circle(50% at 50% 50%);
    box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.1);
}

.person-photo-wrapper > img {
    width: 100%;
}

.cariers {
    display: flex;
    flex-direction: column;
}

.carier {
    background-color: #c0c5e0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    max-width: 250px;
    min-height: 70px;
    justify-content: space-between;
    box-shadow: 10px -10px 10px 1px hsl(231, 100%, 95%);
}

.carier .carier-title {
    font-weight: bold;
    text-transform: uppercase;
}

.carier .carier-date {
    text-align: center;
}

</style>