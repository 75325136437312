<template>
    <div class="cover">
        <img :src="source.cover" />
    </div>    
</template>

<script>

export default {
    components: {    
    }, 
    mounted() {
    },
    emits: ['play'],
    props: {
        source: {
            type: Object,
        },
    },
    data() {
        return {
        }
    },
    methods: {
        play() {
            this.$ref.emit('play', this.source);
        }
    },
}

</script>

<style scoped>

.cover {
    border-radius: 39px;
    background-color: #00a0bc;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    box-shadow: 0px 4px 13px 0px rgba(0,0,0,0.1);
    margin-top: 20px;
    margin-left: -10px;
}

.cover > img {
    width: 100%;
    border-width: 0px;
    border-radius: 38px;
    background-image: linear-gradient(180deg, rgba(88,101,173,0) 0%, rgba(135,85,173,0.63) 78%);
    border-color: transparent;
    border-style: solid;
    margin-top: -20px;
    margin-left: 10px;
}

</style>