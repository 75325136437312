<template>
<div class="vselect" :class="{ disabled: !enabled }" @click="toggle">
    <span class="dropdown-toggle"></span>
    <div class="vselect-input"><span v-if="active">{{ active.label }}</span></div>
    <div class="drop-container" :class="{ opened: isOpen }">
        <ul class="option-list">
            <li v-for="item in items" :key="item.label" @click="setActiveValue(item)" :class="{ active: item == active }">
                <span>{{ item.label }}</span>
            </li>
        </ul>
    </div>
</div>
</template>

<script>

export default {
    props: {
        items: {
            type: Array,
            default: [],
        },
        prepend: {
            type: String,
            default: undefined,
        },
        value: {
            default: undefined,
        },
        permanent: {
            type: Boolean,
            default: true,
        },
        enabled: {
            type: Boolean,
            default: true
        }
    },
    mounted: function() {
        this.setValue();
    },
    watch: {
        'items'() {
            this.setValue();
        },
        'value'() {
            console.log('value');
            this.setValue();
        },
        'enabled'() {
            if ( !this.enabled ) this.isOpen = false;
        }
    },
    emits: ['change'],
    data() {
        return {
            active: undefined,
            isOpen: false,
        }
    },
    methods: {
        toggle() {
            if (this.enabled) {
                this.isOpen = ! this.isOpen;
            }            
        },
        setActiveValue(item) {
            if (this.permanent) {
                this.active = item;
            } else {
                this.active = undefined;
            }
            this.$emit('change', item);
        },
        setValue() {
            console.log("Set value", this.value);
            if ( this.value === undefined ) {                
                this.active = undefined;
            } else {
                let find = false;
                for (let e of this.items) {
                    if ( e.code === this.value ) {
                        this.active = e;
                        find = true;
                        break;
                    }
                }
                if (! find) {
                    this.active = undefined;
                }
                console.log( this.active );
            }
        }
    }
}
</script>

<style scoped>
div.vselect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;    
    padding: 0;
    cursor: pointer;
    position: relative;
    margin: 0;
    border: 1px #d9d9d9 solid;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    min-width: 200px;
    background: #ffffff none;
    background-clip: padding-box;
}

div.vselect.disabled {
    background-color: rgba(0,0,0,0.1);
    color: rgba(0,0,0,0.7);
}

.vselect .dropdown-toggle {
    width: 36px;
    height: 36px;
    display: block;
    position: relative;
}

.vselect .dropdown-toggle::before {
    margin-left: -1.1rem;
    display: block;
    position: absolute;
    color: white transparent;
    font-size: 0;
    content: "";
    height: 0.3125rem;
    width: 0.3125rem;
    background-color: white transparent;
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-color: #1d1d1d;
    top: 50%;
    left: 100%;
    margin-top: -0.1625rem;
    z-index: 2;
    transform: rotate(-45deg);
    transition: all 0.15s easy-in-out;
    transform-origin: center center 1px;
}

.vselect .vselect-input {
    order: 2;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    cursor: pointer;
    width: calc(100% - .1rem);
    height: 34px;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0;
}

.vselect .drop-container {
    display: none;
    max-height: 400px;
    overflow-y: scroll;
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% + 2px);
    height: auto;
    border: 1px solid #dfdfdf;
    padding: 2px;
    margin-left: -1px;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.3);
}

.vselect .drop-container.opened {
    display: block;
}

.vselect .option-list {
    margin: 0;
    padding: 0;
    list-style: none inside;
    overflow-y: auto;
    position: relative;
    display: block;
    background-color: #ffffff;
    font-size: 0.875rem;
}

.vselect ul {
    right: 0;
    left: 0;
    border: 0;
}

.vselect .option-list li {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none inside;
    font-size: 0.875rem;
}

.vselect .option-list li:hover {
    background-color: rgba(29, 29, 29, 0.1);
}

.vselect .option-list li.active {
    background-color: #5ebdec;
    color: #ffffff;
    font-weight: bold;
}

.vselect .option-list span {
    min-width: auto;
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 2rem 0 2.5rem;
    display: block;
}
</style>