<template>
    <div class="footer">
        <div class="footer-section">
            <div>
                <img src="/logo.svg" class="logo" />
            </div>            
            <div style="margin-top: 30px;"> 
                <b>Контактный центр:</b> горячая линия, продажи по телефону, программное обеспечение для отдела продаж и колл-центра.
            </div>
        </div>
        <div class="footer-section">
            <span class="footer-section-title">КОНТАКТЫ:</span>
            <div> 
                <div class="footer-item">
                    <img src="/phone.svg"/>
                    <span>{{ phone }}</span>
                </div>
                <div class="footer-item">
                    <img src="/email.svg"/>
                    <span>{{ email }}</span>
                </div>
            </div>
        </div>
        <div class="footer-section">
            <span class="footer-section-title">Мы в социальных сетях:</span>
            <div> 
                <div class="footer-item">
                    <img src="/vk.svg"/>
                    <span>{{ vk }}</span>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>

import Video from './Video.vue'

export default {
    components: {
        Video
    }, 
    mounted() {
    },
    props: {
        phone: {
            type: String,
            default: '+7(905)177-07-87',
        },
        email: {
            type: String,
            default: 'rabota@mixtell.ru',
        },
        vk: {
            type: String,
            default: 'mixtell2020',
        }
    },
    data() {
        return {
        }
    },
    methods: {
    },
}

</script>

<style scoped>

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo {
    max-height: 51px;
    margin-top: -30px;
}

.footer-section {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.footer-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
}

.footer-item > img {
    width: 32px;
    margin-right: 15px;
}

.footer-section-title {
    font-weight: bold;
    margin-bottom: 20px;
}

</style>