<template>
  <div class="header-wrapper">
    <Header :units="units" @wantToWork="gotoForm" @unit="u => { unit=u; }"></Header>    
  </div>
  <div class="banner">
    <span style="font-weight: bold; font-size:xx-large;">НОВЫЕ УСЛОВИЯ ОПЛАТЫ!</span>
    <span style="font-weight: bold; font-size:x-large;">ЗАРАБОТНАЯ ПЛАТА ОТ <span style="font-size:xx-large;">50 000</span> РУБЛЕЙ</span>
    <span style="font-weight: bold;">нажми чтобы узнать подробности</span>
  </div>
  <div class="section about">
    <About :story="story" @wantToWork="gotoForm"></About>
  </div>
  <div class="section principles">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <img src="/__MIXTELL_PRINCIPLES.svg" />
      <div style="padding-left: 100px">
        <img src="/wave.svg" />
      </div>
    </div>
    <div class="principle" v-for="p in principles" :key="p.title">
        <img src="/principle-icon.svg" />
        <div class="item-title">{{ p.title }}</div>
        <div></div>
        <div class="item-text">{{ p.text }}</div>
    </div>
  </div>
  <div class="section videos">
    <div class="video-items">
      <Video v-if="unit" v-for="v in videos[unit.title]" :key="v.title" :source="v"></Video>
    </div>
  </div>
  <div class="section">
    <Vacant :vacants="vacants"></Vacant>
  </div>
  <div class="section garanties">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <img src="/__MIXTELL_GARANTIES.svg" />
      <div style="padding-left: 100px">
        <img src="/wave.svg" />
      </div>
    </div>    
    <div class="garant-list">
      <img src="/garanty-1.svg" />
      <img src="/garanty-2.svg" />
      <img src="/garanty-3.svg" />
      <img src="/garanty-4.svg" />
      <img src="/garanty-5.svg" />
      <img src="/garanty-6.svg" />
    </div>
  </div>
  <div class="section-header">
    <div style=" flex: 1; display: flex; align-items: center; justify-content: center; padding-right: 200px;">
      <img src="/__MIXTELL_STORIES.svg" />
    </div>
  </div>
  <div class="section stories">
    <Stories :stories="stories[unit.title]" v-if="unit"></Stories>
  </div>
  <div class="section-header">
    <div style=" flex: 1; display: flex; align-items: center; justify-content: center;">
      <img src="/__MIXTELL_RECALLS.png" style="height: 34px;" />
    </div>
  </div>
  <div class="section recalls">    
    <Recalls :recalls="recalls"></Recalls>
  </div>

  <div class="section-header">
    <div style=" flex: 1; display: flex; align-items: center; justify-content: center; padding-right: 200px;">
      <img src="/__MIXTELL_FUN.svg" />
    </div>
  </div>
  <div class="section">
    <div class="galery" ref="galery"></div>
  </div>

  <div class="section-wrapper-1">
  <div class="section address" v-if="unit">
    <div class="section-header">
      <div style=" flex: 1; display: flex; align-items: center; justify-content: left;">
        <img src="/__MIXTELL_ADDRESS.svg" />
      </div>
    </div>
    <div class="section unit">
      <div class="unit-name">
        г. {{ unit.title }}
      </div>
      <div class="unit-address">
        {{ unit.address }}
      </div>
      <div class="unit-phone">
        {{ unit.phone }}
      </div>
      <iframe :src="unit.map" allowfullscreen="true" style="position:relative;"></iframe>
    </div>    
  </div>
  </div>

  <div class="section-header">
    <div style=" flex: 1; display: flex; align-items: center; justify-content: center; padding-right: 200px;">
      <div style="display: flex; flex-direction: column;">
        <img src="/__MIXTELL_INVITATION.svg" />      
      </div>      
    </div>
  </div>
  <div class="section-wrapper-2"  ref="form">
    <div style="font-size: x-large;" class="section">
          Мы рады всем, кто хочет приходить на работу с удовольствием и каждый день развиваться.
<b>Присоединяйтесь!</b>
        </div>
  <div class="section form">
    <div class="re-form" style="width: 450px">
      <div class="input">
        <div class="label">Имя</div>
        <AltInput></AltInput>
      </div>
      <div class="input">
        <div class="label">Вакансия</div>
        <Select :items="vacants.map( e => {return { label: e.name, code: e.name }} )"></Select>
      </div>
      <div class="input">
        <div class="label">Телефон</div>
        <AltInput :prepend="'+7'"></AltInput>
      </div>
      <div class="input">
        <div class="label"></div>
        <div class="button gradient" style="width: 235px; text-align: center;">ЗАПОЛНИТЬ АНКЕТУ</div>
      </div>
      <span style="font-size: 12px;">Отправляя данные с помощью этой формы вы подтверждаете, что ознакомились и согласны с нашей <a style="color: #ff8562; text-decoration: none;" href="/policy.html">Политикой конфиденциальности</a></span>
    </div>
    <div>
      <img src="/advantages.svg" width="450px" />
    </div>
  </div>
  </div>

  <div class="section footer">
    <Footer class="footer-wr"></Footer>
  </div>  
</template>

<script>

import Header from './Header.vue'
import About from './About.vue'
import Video from './Video.vue'
import Vacant from './Vacant.vue'
import Stories from './Stories.vue'
import Recalls from './Recalls.vue'
import Footer from './Footer.vue'

import AltInput from '@/metro-ui/AltInput'
import Select from '@/metro-ui/Select'

export default {
  components: {
    Header, About, Video, Vacant, Stories, Recalls, Footer,
    AltInput, Select,
  }, 
  mounted() {
    this.unit = this.units[0];
    const galery = this.$refs["galery"];
    console.log(galery);
    const jG = window.$(galery)
    jG.nanogallery2( {
				"thumbnailWidth": this.autosize ? "auto" : 200,
  				"thumbnailHeight": this.autosize ? 150 : "auto",
				galleryMaxItems: this.autosize ? 8 : 0,
				items: this.photos,
    } );
  },
  data() {
    return {
      autosize: false,
      units: [ 
        { title: "Орёл", address: "Маслозаводской переулок, дом 2, 2 этаж", phone: "+7(903)637-00-18", map: "https://yandex.ru/map-widget/v1/?ll=36.050668%2C52.939057&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzE2NTQzNRJI0KDQvtGB0YHQuNGPLCDQntGA0ZHQuywg0JzQsNGB0LvQvtC30LDQstC-0LTRgdC60L7QuSDQv9C10YDQtdGD0LvQvtC6LCAyIgoN4jMQQhWZwVNC&z=17.14" }, 
        { title: "Брянск", address: "улица Дуки, дом 37", phone: "+7(905)177-07-87", map: "https://yandex.ru/map-widget/v1/?ll=34.364368%2C53.260737&mode=search&oid=138524351264&ol=biz&z=17.08" } ],
      story: {
        cover: "/photo/photo_2024-07-09_12-39-13.jpg"
      },
      unit: undefined,
      principles: [ 
          { title: "МЫ ДЕЙСТВУЕМ", text: "Если что-то делать внимательно некоторое время – у вас начнет получаться, будет получаться – начнет нравиться, будет нравиться – вы захотите еще больше это делать." }, 
          { title: "МЫ ЗА ОТВЕТСТВЕННОСТЬ", text: "Мы работаем в команде. Мы – команда взрослых и профессиональных людей. Наши сотрудники имеют полную свободу в принятии решений и несут ответственность за них."},
          { title: "МЫ ЗА ГИБКОСТЬ", text: "Гибкие графики работы, гибкие подходы к проектам. Мы выявляем сильные стороны каждого сотрудника и готовы идти на встречу каждому." },
      ],
      videos: {
        'Брянск': [
            /*
            { cover: '/photo/video-1.jpg' },
            { cover: '/photo/video-2.jpg' },
            { cover: '/photo/video-3.jpg' },
            { cover: '/photo/video-4.jpg' },
            { cover: '/photo/video-5.jpg' },
            { cover: '/photo/video-6.jpg' },
            */
            { cover: '/photo/Данилов.jpg' },
            { cover: '/photo/Зимина.JPG' },
            { cover: '/photo/Михалева.JPG' },
            { cover: '/photo/Обухова.JPG' },
            { cover: '/photo/Селькина.JPG' },
            { cover: '/photo/Филина.jpg' },
        ],
        'Орёл': [
            { cover: '/photo/Назаренко Дмитрий.JPG' },
            { cover: '/photo/Гнеушева Маргарита.jpg' },
            { cover: '/photo/Карнюшкина Ирина.jpg' },
            { cover: '/photo/Мясищева Ольга.jpg' },
            { cover: '/photo/Савоськина Светлана.JPG' },
            { cover: '/photo/Белова - Булгакова Александра.png' },
        ]
      },
      vacants: [
          { name: "Менеджер по страхованию", count: "50 000", photo: '/photo/vacant-1.png', about: '<p>Вы будете оказывать консультацию и оформлять страховые полисы по телефону. Оформлять полис клиентам, которые уже согласились на расчет по страхованию КАСКО или КАСКО + ОСАГО. Фиксировать данные в программе.</p><p>Мы трудоустраиваем в соответствии с законодательством РФ по ТД. У нас в компании своевременная  «Белая» заработная плата, состоящая из оклада и премии.</p><p>Также есть дополнительное премирование за опыт и квалификацию, возможность заработать от 3000-8000 рублей за каждого приведенного в компанию друга.</p>' },
          { name: "Специалист по страхованию", count: "40 000", photo: '/photo/vacant-2.png', about: '<p>Вы будете оказывать консультирование клиентов по вопросам страхования, подбор оптимального варианта. Производить расчет стоимости страхового полиса в специальной программе.</p><p>Мы предлагаем:</p><ul><li>официальное трудоустройство по ТК;</li><li>удобный график работы: 5/2, смены 6-8 ч;</li><li>оплачиваемое обучение;</li><li>дополнительные бонусы и призы за победу в корпоративных конкурсах;</li><li>корпоративная связь;</li><li>стремительный карьерный рост.</li>' },
      ],
      stories: {
        'Брянск': [
            { who: "Екатерина Зимина", photo: "/photo/Зимина.png", current: { title: "Руководитель Обособленного подразделения", date: "сентябрь 2023" }, prev: { title: "Супервайзер", date: "сентябрь 2022" }, text: "Mixtell предоставляет все возможности для карьерного роста. При поддержке руководства компании и более опытных коллег, я довольно быстро смогла подняться по карьерной лестнице и занять должность руководителя подразделения." },
            { who: "Оксана Павленко", photo: "/photo/Павленко.png", current: { title: "Супервайзер", date: "сентябрь 2023" }, prev: { title: "Специалист КЦ", date: "ноябрь 2021" }, text: "Профессиональный коллектив, качество работы и уверенность в завтрашнем дне - так бы я описала компанию Mixtell. Регулярные тренинги помогают сотрудникам развиваться и получать новые навыки, что помогает специалистам идти вперед по карьерной лестнице и увеличивать свой доход." },
            { who: "Анатолий Петрухин", photo: "/photo/Петрухин.png", current: { title: "Супервайзер", date: "март 2022" }, prev: { title: "Специалист КЦ", date: "февраль 2021" }, text: "За два года работы в Mixtell коллектив стал уже родным.  Дружные, отзывчивые, готовые всегда прийти на помощь. В компании я приобрел бесценный опыт работы и получил новые навыки, помогающие мне в профессиональном росте." },
            { who: "Ирина Большунова", photo: "/photo/Большунова.png", current: { title: "Супервайзер", date: "апрель 2022" }, prev: { title: "Специалист КЦ", date: "апрель 2021" }, text: "Компания постоянно развивается, открывает новые интересные проекты, благодаря которым сотрудники приобретают новые навыки." },
            { who: "Амплеева Валерия", photo: "/photo/Амплеева.png", current: { title: "Супервайзер", date: "март 2024" }, prev: { title: "Менеджер по работе с клиентами", date: "сентябрь 2022" }, text: "Интересная работа, динамичная обстановка и возможность влиять на процессы делают работу здесь по-настоящему увлекательной. Я благодарна за возможность быть частью этой команды и надеюсь на долгосрочное сотрудничество с Mixtell." },
            { who: "Екатерина Обухова", photo: "/photo/Обухова.png", current: { title: "Менеджер по внутренним коммуникациям и корпоративной культуре", date: "июль 2023" }, prev: { title: "Специалист КЦ", date: "ноябрь 2022" }, text: "К каждому сотруднику в компании индивидуальный подход. Кроме того, в Mixtell царит дружественная атмосфера. Коллектив и наставники всегда готовы помочь и поддерживают на каждом этапе развития." },
        ],
        'Орёл': [
          { who: "Маргарита Гнеушева", photo: "/photo/Гнеушева.png", current: { title: "Руководитель подразделения", date: "октябрь 2023" }, prev: { title: "Бухгалтер по расчету ЗП", date: "август 2020" }, text: "Работая в Mixtell, я чувствую, что моя работа имеет значение, и вижу результаты своей деятельности каждый день. Это компания, где ценят сотрудников и создают условия для их развития. Я горжусь тем, что являюсь частью этой команды!" },
          { who: "Бокарёв Леонид", photo: "/photo/Бокарёв.png", current: { title: "Специалист по контролю качества", date: "октябрь 2022" }, prev: { title: "Специалист КЦ", date: "сентябрь 2020" }, text: "Компания учла мои компетенции и предложила должность, которая мне нравится и благодаря которой я с удовольствием хожу на работу." },
          { who: "Варникова Оксана", photo: "/photo/Варникова.png", current: { title: "Руководитель группы", date: "январь 2023" }, prev: { title: "Специалист КЦ", date: "октябрь 2020" }, text: "Присоединившись к команде Mixtell, я была приятно удивлена тем, насколько гибко и эффективно здесь проходит обучение. Все сотрудники компании готовы помочь и поддержать, а опытные тренеры делают все возможное, чтобы каждый новичок освоил навыки успешных продаж." },
          { who: "Евсикова Людмила", photo: "/photo/Евсикова.png", current: { title: "Руководитель группы", date: "март 2023" }, prev: { title: "Специалист КЦ", date: "март 2021" }, text: "В компании Mixtell ни один сотрудник не остаётся без внимания - а это в наше время редкость.  Подробное обучение и наставничество позволяют сотрудникам быстро освоиться и эффективно выполнять поставленные задачи." },
          { who: "Колясникова Валерия", photo: "/photo/Колясникова.png", current: { title: "Супервайзер", date: "ноябрь 2022" }, prev: { title: "Специалист КЦ", date: "сентябрь 2020" }, text: "В Mixtell большой выбор направлений проектов, можно выбрать более подходящий и комфортный для себя. Сотрудники компании постоянно развиваются в профессиональном плане, обучаются, получают новые навыки и знания." },
          { who: "Михайлина Анастасия", photo: "/photo/Михайлина.png", current: { title: "Руководитель группы", date: "апрель 2023" }, prev: { title: "Специалист КЦ", date: "сентябрь 2021" }, text: "Компания постоянно развивается, открывает новые интересные проекты, благодаря которым сотрудники приобретают новые навыки." },
          { who: "Названова Анастасия", photo: "/photo/Названова.png", current: { title: "Руководитель исходящих проектов", date: "ноябрь 2022" }, prev: { title: "Супервайзер", date: "ноябрь 2020" }, text: "Компания Mixtell помогает расти и развиваться в разных направлениях и также достигать личной самореализации. Mixtell - отличное место для работы." },
          { who: "Паронян Анна", photo: "/photo/Паронян.png", current: { title: "Супервайзер", date: "ноябрь 2022" }, prev: { title: "Специалист КЦ", date: "август 2020" }, text: "Mixtell дает возможность расти по карьерной лестнице, почти все руководители начинали работу в качестве специалистов. Необходимо личное желание и стремление сотрудника двигаться вперед." },
          { who: "Черевкова Наталья", photo: "/photo/Черевкова.png", current: { title: "Младший специалист по обучению персонала", date: "декабрь 2023" }, prev: { title: "Специалист КЦ", date: "август 2020" }, text: "Mixtell дает возможность расти по карьерной лестнице, почти все руководители начинали работу в качестве специалистов. Необходимо личное желание и стремление сотрудника двигаться вперед." },
          { who: "Чупахина Наталья", photo: "/photo/Чупахина.png", current: { title: "Специалист технической поддержки", date: "декабрь 2022" }, prev: { title: "Специалист КЦ", date: "июль 2021" }, text: "Мне очень приятно, что мои навыки и познания в разных сферах пригодились мне в работе и компании в целом. Благодарю весь коллектив Микстелл за доверие." },
          { who: "Карнюшкина Ирина ", photo: "/photo/Карнюшкина.png", current: { title: "Специалист по подбору персонала", date: "сентябрь 2020" }, prev: { title: "Руководитель группы продаж", date: "август 2020" }, text: "В компании отличное руководство, индивидуальный подход к каждому сотруднику. Регулярно проходят тренинги профессионального роста, что помогает специалистам расти и продвигаться по карьерной лестнице." },
          { who: "Юрова Екатерина ", photo: "/photo/Юрова.png", current: { title: "Руководитель проектов", date: "ноябрь 2020" }, prev: { title: "Руководитель группы продаж", date: "сентябрь 2020" }, text: "Если хочешь работу, на которую будешь приходить с удовольствием и ежедневно учиться чему-то новому, то тебе точно в нашу команду Mixtell!" },
          { who: "Мясищева Ольга ", photo: "/photo/Мясищева.png", current: { title: "Руководитель группы", date: "август 2023" }, prev: { title: "Специалист", date: "февраль 2023" }, text: "Благодарна компании и коллегам за всестороннюю поддержку и доверие, что помогло мне уже в кратчайшие сроки стать Руководителем группы на проекте Ипотека. Продолжаю расти и развиваться вместе с компанией, тем более, что все возможности для этого Mixtell дает в полной мере." },
        ]
      },
      recalls: [
          { who: "Ларикова Виктория", photo: "/photo/Ларикова.png", unit: "Брянск", text: "Работаю в компании уже год. Меня окружает дружелюбный и отзывчивый коллектив, который всегда готов оказать поддержку.&nbsp;Офис чистый и расположен в центре города. Зарплата выплачивается вовремя. При выполнении плана выходит хорошая премия, которая является дополнительным стимулом работать в Mixtell." },
          { who: "Евсикова Людмида", photo: "/photo/Евсикова-2.png", unit: "Орёл", text: "В компании я работаю с января 2021 года. Пришла по отзывам&nbsp;подруги и не пожалела. Быстро влилась в новый коллектив и в работу благодаря отзывчивости и доброжелательности сотрудников. Коллеги с лёгкостью делятся навыками и знаниями, и в этом огромная заслуга руководящего состава, так как все наши СВ, РГП, тренера показывают на личном примере достойное отношение к сотрудникам. В нашей компании ни один сотрудник не остаётся без внимания - а это в наше время редкость." },
          { who: "Савинкина Татьяна", photo: "/photo/Савинкина.png", unit: "Орёл", text: "В Mixtell я работаю с сентября 2020 года. Я считаю, что компания – это не просто работа, а вторая семья, которая всегда поддержит, даст хороший совет и не оставит в трудную минуту. В настоящее время тяжело найти такую компанию. Микстелл совмещает в себе и дружный коллектив, и достойную заработную плату." },
          { who: "Карытко Кристина", photo: "/photo/Карытко.png", unit: "Брянск", text: "В Mixtell я работаю уже больше двух лет. За это время компания стала моим вторым домом, а наш дружный коллектив - как семья, где всегда помогут, подскажут и пойдут на встречу. В офисе создана благоприятная обстановка как в плане коллективного общения, так и с точки зрения уюта. Хочу пожелать всему составу компании процветания, интересных прибыльных проектов, операторам еще побольше лояльных клиентов и всего самого наилучшего. Всем удачи и добра!" },
          { who: "Кузнецова Ольга", photo: "/photo/Кузнецова.png", unit: "Брянск", text: "Mixtell предоставляет комфортные условия для работы, руководство всегда подскажет, как поступить в той или иной ситуации. Достойный уровень оплаты труда. Благодаря Mixtell я стала разнопрофильным специалистом и нашла именно то, что мне нужно." },
          { who: "Башкатова Наталья", photo: "/photo/Башкатова.png", unit: "Орёл", text: "В компании есть уникальная система семинаров и тренингов, способствующих профессиональному развитию сотрудников и их карьерному росту. Комфортные условия труда, наличие системы наставничества." },
          { who: "Павленко Оксана", photo: "/photo/Павленко-1.png", unit: "Брянск", text: "Дружная команда и внимательные руководители - залог успешной работы компании. Здесь действуют отличная программа обучения специалистов и понятная всем система мотивации. Всем рекомендую работу в Mixtell." },
          { who: "Юренкова Ольга", photo: "/photo/Юренкова.png", unit: "Брянск", text: "Отличная рабочая атмосфера, гибкий график и замечательный коллектив. В этом компании всегда можно получить поддержку и понимание. Оперативно оказывается помощь в рабочих моментах." },
          { who: "Разумахина Ксения", photo: "/photo/Разумахина.png", unit: "Брянск", text: "Мне нравится работать в Mixtell. Огромный плюс - удобный график. В компании сложено дружеское общение между коллегами и руководством. Также можно обсудить любую идею и вопросы." },
          { who: "Федорова Ольга", photo: "/photo/Федорова.png", unit: "Брянск", text: "В компании Mixtell я работаю уже год. За это время взяла в работу большой поток информации и увеличила эффективность продаж. В этой компании я познакомилась с хорошими людьми, которые всегда смогут оказать поддержку." },
          { who: "Фролкин Максим", photo: "/photo/Фролкин.png", unit: "Брянск", text: "Я работаю на проекте страхования автомобилей. На протяжении каждого дня я обмениваюсь информацией с большим количеством людей. Руководители - профессионалы и всегда дают правильное направление для достижения отличного результата." },
      ],
      photos: [
          { src: '/galary/1_.jpg', srct: '/galary/1_t.jpg' },
					{ src: '/galary/2_.jpg', srct: '/galary/2_t.jpg' },
					{ src: '/galary/3_.jpg', srct: '/galary/3_t.jpg' },
					{ src: '/galary/4_.jpg', srct: '/galary/4_t.jpg' },
					{ src: '/galary/6_.jpg', srct: '/galary/6_t.jpg' },
					{ src: '/galary/7_.jpg', srct: '/galary/7_t.jpg' },
					{ src: '/galary/10_.jpg', srct: '/galary/10_t.jpg' },
					{ src: '/galary/11_.jpg', srct: '/galary/11_t.jpg' },
					{ src: '/galary/12_.jpg', srct: '/galary/12_t.jpg' },
					{ src: '/galary/13_.jpg', srct: '/galary/13_t.jpg' },
					{ src: '/galary/14_.jpg', srct: '/galary/14_t.jpg' },
					{ src: '/galary/15_.jpg', srct: '/galary/15_t.jpg' },
					{ src: '/galary/16_.jpg', srct: '/galary/16_t.jpg' },
					{ src: '/galary/17_.jpg', srct: '/galary/17_t.jpg' },
					{ src: '/galary/18_.jpg', srct: '/galary/18_t.jpg' },
					{ src: '/galary/19_.jpg', srct: '/galary/19_t.jpg' },
					{ src: '/galary/20_.jpg', srct: '/galary/20_t.jpg' },
					{ src: '/galary/21_.jpg', srct: '/galary/21_t.jpg' },
					{ src: '/galary/22_.jpg', srct: '/galary/22_t.jpg' },
					{ src: '/galary/23_.jpg', srct: '/galary/23_t.jpg' },
          { src: '/galary/new/1.jpg', srct: '/galary/new/thumb_1.jpg' },
          { src: '/galary/new/2.jpg', srct: '/galary/new/thumb_2.jpg' },
          { src: '/galary/new/3.jpg', srct: '/galary/new/thumb_3.jpg' },
          { src: '/galary/new/4.jpg', srct: '/galary/new/thumb_4.jpg' },
          { src: '/galary/new/5.jpg', srct: '/galary/new/thumb_5.jpg' },
          { src: '/galary/new/6.jpg', srct: '/galary/new/thumb_6.jpg' },
          { src: '/galary/new/7.jpg', srct: '/galary/new/thumb_7.jpg' },
          { src: '/galary/new/8.jpg', srct: '/galary/new/thumb_8.jpg' },
          { src: '/galary/new/9.jpg', srct: '/galary/new/thumb_9.jpg' },
          { src: '/galary/new/10.jpg', srct: '/galary/new/thumb_10.jpg' },
          { src: '/galary/new/11.jpg', srct: '/galary/new/thumb_11.jpg' },
          { src: '/galary/new/12.jpg', srct: '/galary/new/thumb_12.jpg' },
          { src: '/galary/new/13.JPG', srct: '/galary/new/thumb_13.JPG' },
          { src: '/galary/new/14.JPG', srct: '/galary/new/thumb_14.JPG' },
          { src: '/galary/new/15.JPG', srct: '/galary/new/thumb_15.JPG' },
          { src: '/galary/new/16.JPG', srct: '/galary/new/thumb_16.JPG' },
          { src: '/galary/new/17.jpg', srct: '/galary/new/thumb_17.jpg' },
          { src: '/galary/new/18.jpg', srct: '/galary/new/thumb_18.jpg' },
          { src: '/galary/new/19.jpg', srct: '/galary/new/thumb_19.jpg' },
          { src: '/galary/new/20.JPG', srct: '/galary/new/thumb_20.JPG' },
      ]
    }
  },
  methods: {
    gotoForm() {
      console.log( this.$refs["form"] );
      this.$refs["form"].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
}

</script>

<style>
@font-face {
  font-family: 'Montserrat';
  src: url(../public/Montserrat-VariableFont_wght.ttf) format("truetype");
}

#app {
  font-family: 'Montserrat',Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  box-sizing: content-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow:hidden;
}

* {
    margin: 0;
    padding: 0;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9998;
}

.button {
  cursor: pointer;
}

.section-header {
  position: relative; 
  display: flex; 
  flex-direction: row; 
  gap: 40px; 
  width: 100%; 
  align-items: center; 
  justify-content: space-between;
  margin-top: 80px;
}

.section-header::after {
  content: '';
  background-image: url("../public/wave.svg");
  height: 74px;
  position: absolute; 
  width: 400px;
  right: -80px;
}

.button.gradient {
  color: #ffffff;
  font-size: 20px;
  font-family: 'Montserrat',Arial,sans-serif;
  line-height: 1.55;
  font-weight: 600;
  border-radius: 17px;
  background-image: linear-gradient(130deg, rgba(147,129,208,1) 0%, rgba(71,116,177,1) 100%);
  border-color: transparent;
  border-style: solid;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
}

.button.gradient:hover {
  background-image: linear-gradient(292deg, rgba(90,104,175,1) 0%, rgba(0,157,163,1) 100%);
}

.banner {
  margin-top: 110px;
  left: 0px;
  width: 100%;
  height: 150px;
  background-image: url('../public/banner-background.png');
  cursor: pointer;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #00a0bc;
}

.banner > * {
  padding: 5px;
}

.section {
  width: 1200px;
  margin-top: 50px;
}

.principle {
  display: grid;
  grid-template-columns: fit-content(8ch) 1fr;
  row-gap: 20px;
  column-gap: 40px;
  margin-top: 20px;
  align-items: center;
}

.principle .item-title {
  border-radius: 19px;
  background-color: #00a0bc;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.2);
  width:fit-content;
  color: white;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: x-large;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.principle .item-text {
  font-size: large;
  line-height: 1.6;
}

.videos {
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100% !important;
  background-image: url("../public/banner-background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.video-items {
  max-width: 1000px;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  row-gap: 20px;
  column-gap: 60px;
}

.garanties {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.garant-list {
  margin-top: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.section.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dee6f8;
  height: 250px;
}

.footer-wr {
  width: 1200px;
}

.galery {
  padding: 50px;
  border: 1px solid rgba(192,197,224, 0.5);
  border-radius: 20px;
  box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.1);
}

.address {
}

.unit {
  font-size: xx-large;
}

.unit > * {
  padding-top: 12px;
  padding-bottom: 12px;
}

.unit > iframe {
  width: 100%;
  border: none;
  height: 400px;
  margin-top: 70px;
}

.unit-name {  
  font-weight: bold;
  width: 258px;
  text-align: center;
  border-bottom: 1px solid #5a68af;
  margin-bottom: 20px;
  font-size: xx-large;
}

.unit-address {
}

.section-wrapper-1 {
  width: 100%;
  background-image: url("../public/tild3966-3561-4630-b339-6339323066317.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.section-wrapper-2 {
  width: 100%;
  background-image: url("../public/tild6261-6436-4265-b336-3061663234358.png");
  background-position-x: right;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  margin-bottom: 40px;
}

.label {
  padding: 5px;
}

.vinput,.vselect {
  width: 100%;
  border: 2px solid #61c7c0 !important;
  border-radius: 7px;
  color: rgb(97, 97, 97) !important;
  padding: 5px !important;
  box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.1);
}

.vinput .prepend {
  background-color: inherit !important;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.footer {
  margin-top: 0;
}

</style>
