<template>
  <div class="header">
    <div class="left">
        <img src="/logo.svg" />
    </div>
    <div class="center"></div>
    <div class="right">
        <div class="units panel-item"  @mouseenter="selectUnit = true"  @mouseleave="selectUnit = false">
            <div class="unit-current">{{ unit.title }}</div>
            <div class="units-selector" v-show="selectUnit">
                <div class="unit-item" :class="{ selected: unit == u }" v-for="u in units" :key="u.title" @click="setUnit(u)">{{ u.title }}</div>
            </div>
        </div>
        <div class="panel-item" style="min-width: 200px;">
            {{ unit.phone }}
        </div>
        <div class="panel-item" style="min-width: 250px;">
            <div class="button gradient" @click="$emit('wantToWork')">ХОЧУ РАБОТАТЬ</div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {    
    }, 
    mounted() {
    },
    emits: [ "wantToWork", "unit" ],
    props: {
        units: {
            type: Array,
            default: [],
        },
        phone: {
            type: String,
            default: "+7(905)177-07-87",
        }
    },
    data() {
    return {
        unit: this.units[0],
        selectUnit: false,
    }
    },
    methods: {
        setUnit(u) {
            this.unit = u;
            this.$emit('unit', u);
            this.selectUnit = false;
        }
    },
}

</script>

<style scoped>

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100px;
    background-color: #ffffff;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    box-shadow: 4px 0px 21px 0px rgba(0,0,0,0.1);
    color: #00a0bc;
    font-size: larger;
    font-weight: bold;
}

.header .left {
    padding-left: 20px;
}

.header .center {
    flex: 1;
}

.header .right {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.header .units {
    cursor: pointer;
    position: relative;
}

.header .units-selector {
    position: absolute;
    top: 75px;
    left: -10px;
    min-width: 100px;
    padding: 10px;

    background-color: #ffffff;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    box-shadow: 4px 0px 21px 0px rgba(0,0,0,0.2);
}

.header .units-selector .unit-item {
    padding: 10px;
    color: black;
}

.header .units-selector .unit-item.selected {
    color: inherit;
}

.panel-item {
    min-width: 132px;
    margin-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}

</style>
