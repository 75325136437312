<template>
<div class="vinput" :class="{ readonly: readonly }">
    <span v-if="items && promt" class="dropdown-toggle" @click="isActive = !isActive" ref="toggle"></span>
    <input ref="input" id="lazy-input" v-if="lazy" :type="password ? 'password' : 'text'" :readonly="readonly"  :class="{ error: !isValid }" :value="inputValue" @change="$event => produce($event.target.value)" />
    <input ref="input" id="greedy-input" v-if="! lazy" :readonly="readonly" :class="{ error: !isValid }" type="text" :value="inputValue"  @input="$event => produce($event.target.value)" />
    
    <div class="drop-container" :class="{ opened: promtItems.length > 0 && isActive }" v-show="promtItems" ref="container">
        <ul class="option-list">
            <li v-for="item in promtItems" :key="item.value" @click="select(item); isActive=false;">
                <span>{{ item.label || item.value }}</span>
            </li>
        </ul>
    </div>
    <!--
    <div class="button-group">
        <button class="button input-clear-button" tabindex="-1" type="button">
            <span class="default-icon-cross"></span>
        </button>
    </div>
    -->
    <div v-if="prepend" class="prepend">{{ prepend }}</div>
</div>
</template>

<script>

export default {
    props: {
        modelValue: {
            type: String,
            default: undefined,
        },
        prepend: {
            type: String,
            default: undefined,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
        once: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: null,
        },
        validator: {
            type: Function,
            default(_) { return true; }
        },
        promt: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        password: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:modelValue', 'select'],
    mounted: function() {
        if ( this.modelValue !== undefined ) this.inputValue = this.modelValue;
        if ( this.items ) this.updateItems();

        this._hideContainerListener = () => { this.isActive = false; };
        this._preventHideContainerListener = (event) => { event.stopPropagation(); };

        document.addEventListener("click", this._hideContainerListener, false);
        this.$refs.toggle?.addEventListener("click", this._preventHideContainerListener, false);
        this.$refs.container?.addEventListener("click", this._preventHideContainerListener, false);
    },
    unmounted: function() {
        document.removeEventListener("click", this._hideContainerListener, false);
        this.$refs.toggle?.removeEventListener("click", this._preventHideContainerListener, false);
        this.$refs.container?.removeEventListener("click", this._preventHideContainerListener, false);
    },
    watch: {
        'items'() {
            console.log(this.items);
            if (this.items && this.items.length > 0) {
                if ((this.items.length > 1) || (this.items[0].value != this.modelValue))
                    this.isActive = true;
                else 
                    this.isActive = false;
            }
            else {
                this.isActive = false;
            }
            this.updateItems( ! this.promt );
        },
        'modelValue'() {
            console.log( [this.modelValue, this.inputValue] );
            if (this.modelValue !== undefined) this.inputValue = this.modelValue;
        }
    },
    data() {
        return {
            inputValue: '',
            isActive: false,
            isValid: true,
            promtItems: [],
        }
    },
    methods: {
        check(value) {
            this.isValid = this.validator(value);
            return this.isValid;
        },
        focus() {
            console.log("focus");
            console.log( this.$refs["input"] );
            this.$refs["input"].focus();
            this.$refs["input"].select();
        },
        select(item) {
            this.$emit('select', item);
            console.log("SELECT", (item.label || item.value));
            if ( this.promt ) this.inputValue = (item.label || item.value);
        },
        produce(value) {
            if ( ! this.once ) {
                this.inputValue = value;
            }            
            this.$emit('update:modelValue', this.check(value) ? value : undefined);
            this.updateItems();
        },
        updateItems(auto=true) {
            console.log(this.items);
            if (this.items === null) { this.promtItems = []; return; }
            if (! this.promt) { this.promtItems = this.items; return; }
            if (this.inputValue === '') { this.promtItems = this.items; return; }
            this.promtItems = this.items.filter( item => (item.label || item.value).match( new RegExp( this.inputValue, "iug") ) );
            console.log(this.promtItems);
            if (auto && this.promtItems.length != 0 ) this.isActive = true;
        },
    }
}
</script>

<style scoped>
.vinput {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    position: relative;
    border: 1px #d9d9d9 solid;
    color: #1d1d1d;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    background: white none;
    background-clip: padding-box;
    min-width: 0;
}

.vinput input {
    border: none !important;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    height: 34px;
    padding: 0 0.75rem;
    appearance: none;
    display: block;
    outline: none;
    width: 100%;
    min-width: 0;
    font: inherit;
    order: 2;
}

.vinput.readonly {
    background-color: lightgrey;
}

.vinput.readonly input {
    background-color: rgba(0, 0, 0, 0);
}

.vinput .prepend {
    padding: 0.5rem 0.75rem;
    background-color: #f8f8f8;
    color: #1d1d1d;
    line-height: 1.25rem;
    white-space: nowrap;
    position: relative;
    order: 1;
}

.vinput .drop-container {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% + 2px);
    height: auto;
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid #dfdfdf;
    padding: 2px;
    margin-left: -1px;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.3);
}

.vinput .drop-container.opened {
    display: block;
}

.vinput .option-list {
    margin: 0;
    padding: 0;
    list-style: none inside;
    overflow-y: auto;
    position: relative;
    display: block;
    background-color: #ffffff;
    font-size: 0.875rem;
}

.vinput ul {
    right: 0;
    left: 0;
    border: 0;
}

.vinput .option-list li {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none inside;
    font-size: 0.875rem;
    cursor: pointer;
}

.vinput .option-list li:hover {
    background-color: rgba(29, 29, 29, 0.1);
}

.vinput .option-list li.active {
    background-color: #5ebdec;
    color: #ffffff;
    font-weight: bold;
}

.vinput .option-list span {
    min-width: auto;
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 2rem 0 2.5rem;
    display: block;
}

.vinput input.error {
    background-color: rgba(255,0,0,0.4);
}

.vinput .dropdown-toggle {
    width: 36px;
    height: 36px;
    display: block;
    position: relative;
    cursor: pointer;
}

.vinput .dropdown-toggle::before {
    margin-left: -1.1rem;
    display: block;
    position: absolute;
    color: white transparent;
    font-size: 0;
    content: "";
    height: 0.3125rem;
    width: 0.3125rem;
    background-color: white transparent;
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-color: #1d1d1d;
    top: 50%;
    left: 100%;
    margin-top: -0.1625rem;
    z-index: 2;
    transform: rotate(-45deg);
    transition: all 0.15s easy-in-out;
    transform-origin: center center 1px;
}
</style>