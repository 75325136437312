<template>
    <div class="about">
        <div class="content">
            <img src="/__MIXTELL.svg" />
            <p>В <b>Mixtell</b> ты обретёшь <b>настоящих друзей и отличных наставников</b>. На старте мы будем тебя <b>сопровождать и координировать</b> на каждом этапе. А со временем ты сам сможешь помогать младшим коллегам.</p>
            <div class="invitation">
                <div>Приглашаем тебя на позицию</div>
                <div>Специалист по страхованию!</div>
                <div style="margin-top: 40px; width: 250px;">
                    <div class="button gradient" @click="$emit('wantToWork')">
                        ХОЧУ РАБОТАТЬ
                    </div>
                </div>
            </div>
        </div>
        <div class="history">
            <div class="cover">
                <Video :source="story" @play="play"></Video>
            </div>
            <div class="links">
                <p>+ ещё истории<br/>наших сотрудников</p>
            </div>
        </div>
    </div>    
</template>

<script>

import Video from './Video.vue'

export default {
    components: {
        Video
    }, 
    mounted() {
    },
    emits: ['play', "wantToWork"],
    props: {
        story: {
            type: Object,
        },
    },
    data() {
        return {
        }
    },
    methods: {
        play(source) {
            this.$ref.emit('play', source);
        }
    },
}

</script>

<style scoped>

.about {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 0;
    width: 100%;
}

.content {
    width: 600px;
    line-height: 1.6;
    font-size: larger;
}

.content > * {
    padding-bottom: 40px;
}

.content .invitation {
    text-transform:uppercase;
    font-weight: bold;
}

.content .invitation > * {
    padding-top: 5px;
}

.history {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: larger;
    line-height: 1.6;
}

</style>